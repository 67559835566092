<template>
  <div>
    <div>
      <feather-icon
        icon="CalendarIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
      <span>
        <b-dropdown
          :text="datelabel"
          variant="transparent"
          class="chart-dropdown"
          toggle-class="p-50"
        >
          <b-dropdown-item @click="selectdate(1)"
            >7 วันที่ผ่านมา</b-dropdown-item
          >

          <b-dropdown-item @click="selectdate(2)">เดือนนี้</b-dropdown-item>
        </b-dropdown>
        <!--/ title and dropdown -->
        <hr />
        <b-form-group v-if="customdate">
          <h5>ระบุช่วงวันที่</h5>
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="{ mode: 'range' }"
            @input="onDateChange"
          />
        </b-form-group>

        <p v-if="fromDate">ตั้งแต่ {{ fromDate }} ถึง {{ toDate }}</p>
      </span>
      <br />

      <div align="center" v-if="Loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else>
        <br />
        <b-row>
          <b-col xl="6" md="12">
            <div>
              <b-card>
                <b-row>
                  <b-col xl="12" md="12">
                    <h4 align="center">เปรียบเทียบข้อมูลผู้ใช้</h4>
                    <br /><br /><br />
                    <age :wifi_users="data_all"></age>
                  </b-col> </b-row
                ><br /><br /><br /><br />
              </b-card>
            </div>
          </b-col>
          <b-col xl="6" md="12">
            <b-row>
              <b-col xl="12" md="12">
                <b-row>
                  <b-col xl="6" md="6">
                    <b-card>
                      <h4>NEW USERS</h4>

                      <div align="center" v-if="wifi_users.user_all_total > 0">
                        <b-img :src="people" width="100px" />
                        <h4>{{ wifi_users.user_all }}</h4>
                        <h6>{{ wifi_users.user_all_total }} ทั้งหมด</h6>
                        <hr />

                        <div v-if="wifi_users.user_all_back > 0">
                          <div
                            v-if="
                              (wifi_users.user_all * 100) /
                                wifi_users.user_all_back >
                              100
                            "
                          >
                            <h4 style="color: green">
                              +
                              {{
                                (
                                  (wifi_users.user_all * 100) /
                                    wifi_users.user_all_back -
                                  100
                                ).toFixed(0)
                              }}%
                              <h4 v-if="daterange === 1">
                                เทียบกับ 7 วันย้อนหลัง
                              </h4>
                              <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4>
                            </h4>
                          </div>
                          <div v-else>
                            <h4 style="color: red">
                              {{
                                (
                                  (wifi_users.user_all * 100) /
                                    wifi_users.user_all_back -
                                  100
                                ).toFixed(0)
                              }}%
                              <h4 v-if="daterange === 1">
                                เทียบกับ 7 วันย้อนหลัง
                              </h4>
                              <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <br /><br />
                        <h3>ไม่พบข้อมูล</h3>
                      </div>

                      <br />
                    </b-card>
                  </b-col>
                  <b-col xl="6" md="6">
                    <b-card>
                      <h4>ผู้ใช้กลับมาใช้ซ้ำ</h4>

                      <div align="center" v-if="wifi_users.user_all_back > 0">
                        <b-img :src="user_return" width="100px" />
                        <br />
                        <h4>
                          {{
                            (
                              (users_return * 100) /
                              wifi_users.user_all
                            ).toFixed(0)
                          }}% จากทั้งหมด
                        </h4>
                        <br />
                        <hr />
                        <div
                          v-if="(users_return * 100) / users_return_back > 100"
                        >
                          <h4 style="color: green">
                            +
                            {{
                              (
                                (users_return * 100) / users_return_back -
                                100
                              ).toFixed(0)
                            }}%
                            <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4>
                          </h4>
                        </div>
                        <div v-else>
                          <h4 style="color: red">
                            {{
                              (
                                (users_return * 100) / users_return_back -
                                100
                              ).toFixed(0)
                            }}%
                            <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4>
                          </h4>
                        </div>
                      </div>
                      <div v-else>
                        <br /><br />
                        <h3>ไม่พบข้อมูล</h3>
                      </div>
                      <br />
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="12" md="12">
                <b-row>
                  <b-col xl="6" md="6">
                    <b-card>
                      <h4>วันที่ผู้ใช้งานมากสุดในสัปดาห์</h4>
                      <div align="center" v-if="wifi_users.user_all_back > 0">
                        <b-img :src="Calendar" width="100px" />

                        <h1>{{ day_data[0] }}</h1>
                        <hr />
                        <div v-if="day_data[1] > 0">
                          <h4 style="color: green">
                            + {{ day_data[1] }}%
                            <!-- <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4> -->
                          </h4>
                        </div>
                        <div v-else>
                          <h4 style="color: red">
                            {{ day_data[1] }}%
                            <!-- <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4> -->
                          </h4>
                        </div>
                      </div>
                      <div v-else>
                        <br /><br />
                        <h3>ไม่พบข้อมูล</h3>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col xl="6" md="6">
                    <b-card>
                      <h4>เวลาที่ผู้ใช้งานมากสุดในวันนี้</h4>
                      <div align="center" v-if="wifi_users.user_all_back > 0">
                        <b-img :src="clock" width="100px" />
                        <h4>เวลา {{ time_data[0] }} นาฬิกา</h4>
                        <hr />

                        <div v-if="time_data[1] > 0">
                          <h4 style="color: green">
                            + {{ time_data[1] }}%
                            <!-- <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4> -->
                          </h4>
                        </div>
                        <div v-else>
                          <h4 style="color: red">
                            {{ time_data[1] }}%
                            <!-- <h4 v-if="daterange === 1">
                              เทียบกับ 7 วันย้อนหลัง
                            </h4>
                            <h4 v-else>เทียบกับ 1 เดือนย้อนหลัง</h4> -->
                          </h4>
                        </div>

                        <br />
                      </div>
                      <div v-else>
                        <br /><br />
                        <h3>ไม่พบข้อมูล</h3>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- --------------- -->
        <b-row>
          <b-col xl="4" md="6">
            <b-card
              style="padding-bottom: 4em; align-items: center; padding-top: 5em"
            >
              <h4>ผู้ใช้งานสูงสุด</h4>
              <div style="float: left">
                <b-img :src="UserNew" width="120px" />
              </div>
              <div>
                <h1>
                  <b>
                    99+ <br /><br /><br />
                    <br /><br
                  /></b>
                </h1>
              </div>
              <h4>อัพเดทเมื่อวันที่ {{ fromDate }}</h4>
            </b-card>
          </b-col>

          <b-col xl="4" md="6">
            <b-card
              style="padding-bottom: 5em; align-items: center; padding-top: 5em"
            >
              <h4>ผู้ใช้งาน ออนไลน์</h4>
              <div style="float: left"><b-img :src="User" width="120px" /></div>
              <div>
                <h1>
                  <b> 20+ <br /><br /> </b>
                </h1>
              </div>
            </b-card>
          </b-col>
          <b-col xl="4" md="6">
            <b-card style="padding-bottom: 7em; align-items: center">
              <h4>เวลาเฉลี่ยออนไลน์</h4>
              <div style="float: left">
                <b-img :src="timeback" width="120px" />
              </div>
              <div>
                <br /><br /><br />
                <h3>
                  <b> 4 ชั่วโมง </b>
                  <br /><br /><br />
                  <br /><br />
                </h3>
              </div>
              <h4 v-if="daterange === 1">
                <b>5% </b>
                เทียบกับ 7 วันย้อนหลัง
              </h4>
              <h4 v-else>
                <b>10% </b>
                เทียบกับ 1 เดือนย้อนหลัง
              </h4>
            </b-card>
          </b-col>
          <!-- <b-col xl="4" md="6">
            <b-card>
              <div><h3>การสมัครสมาชิก</h3></div>
              <vue-apex-charts
                type="donut"
                height="300"
                class="my-1"
                :options="sessionsByDeviceDonut.chartOptions"
                :series="sessionsByDeviceDonut.series"
              />
            </b-card>
          </b-col> -->
        </b-row>
        <!-- --------------- -->
        <b-row>
          <b-col xl="12" md="12">
            <b-card>
              <h2>ผู้ใช้งาน</h2>
              <b-row>
                <b-col xl="12" md="12">
                  <b-row>
                    <b-col xl="6" md="6">
                      <b-card align="center">
                        <b-row>
                          <b-col xl="6" md="6">
                            <b-img :src="imgfemale" width="100px" />

                            <span v-if="wifi_users.gender_male > 0">
                              หญิง
                              {{
                                (
                                  (wifi_users.gender_male * 100) /
                                  wifi_users.user_all
                                ).toFixed(0) + " %"
                              }}</span
                            >
                            <span v-else> ไม่พบข้อมูล </span>
                          </b-col>
                          <b-col xl="6" md="6">
                            <b-img :src="imgmale" width="100px" />
                            <span v-if="wifi_users.gender_female > 0">
                              <b>
                                ชาย
                                {{
                                  (
                                    (wifi_users.gender_female * 100) /
                                    wifi_users.user_all
                                  ).toFixed(0) + " %"
                                }}</b
                              ></span
                            >
                            <span v-else> ไม่พบข้อมูล </span>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col xl="6" md="12">
                      <div>
                        <vue-apex-charts
                          type="donut"
                          height="300"
                          class="my-1"
                          :options="genderByDeviceDonut.chartOptions"
                          :series="genderByDeviceDonut.series"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="3" md="3"> </b-col>
                    <b-col xl="12" md="12">
                      <b-card>
                        <div id="chart">
                          <apexchart
                            type="bar"
                            height="350"
                            :options="gender.chartOptions"
                            :series="gender.series"
                          ></apexchart>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col xl="3" md="3"> </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- --------------- -->
        <b-row>
          <b-col xl="6" md="12">
            <b-card>
              <b> <h2>OS</h2></b>
              <b-row>
                <!-- {{ data_os }} -->
                <b-col xl="6" md="6" align="center" v-if="data_os[1] == 'IOS'">
                  <b-img :src="IOS" width="120px" /><br />
                  <h4><b>IOS</b> {{ data_os[0] }} %<br /></h4>
                </b-col>
                <b-col
                  xl="6"
                  md="6"
                  align="center"
                  v-if="data_os[1] == 'Android'"
                >
                  <b-img :src="Android" width="120px" /><br />
                  <h4><b>Android</b> {{ data_os[0] }} %<br /></h4>
                </b-col>

                <b-col xl="6" md="6" align="center">
                  <b-img :src="Other" width="120px" /><br />
                  <h4><b>อื่นๆ</b> {{ data_os[2] }} %<br /></h4>
                </b-col>
              </b-row>
              <hr />
              <b> <h2>DEVICES</h2></b>
              <b-row>
                <!-- {{ data_device }} -->
                <b-col
                  xl="6"
                  md="6"
                  align="center"
                  v-if="data_device[1] == 'tablet'"
                >
                  <b-img :src="tablet" width="120px" /><br />
                  <h4><b>Tablet</b> {{ data_device[0] }} %<br /></h4>
                </b-col>
                <b-col
                  xl="6"
                  md="6"
                  align="center"
                  v-if="data_device[1] == 'mobile'"
                >
                  <b-img :src="mobile" width="120px" /><br />
                  <h4><b>Mobile</b> {{ data_device[0] }} %<br /></h4>
                </b-col>
                <b-col
                  xl="6"
                  md="6"
                  align="center"
                  v-if="data_device[1] == 'desktop'"
                >
                  <b-img :src="desktop" width="120px" /><br />
                  <h4><b>Desktop</b> {{ data_device[0] }} %<br /></h4>
                </b-col>

                <b-col xl="6" md="6" align="center">
                  <b-img :src="DEVICES_Other" width="120px" /><br />
                  <h3><b>อื่นๆ</b> {{ data_device[2] }} %<br /></h3>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col xl="6" md="12">
            <b-card>
              <b-row>
                <b-col xl="6" md="12">
                  <h4><b>OVERVIEW</b></h4>
                  <b-card>
                    <b-row>
                      <b-col><b-img :src="house_wifi" width="100px" /></b-col>
                      <b-col align="center"
                        ><b
                          ><br />
                          <b
                            ><h1>{{ wifi_users.locations }}</h1></b
                          >
                          <h5>สถานที่</h5>
                        </b></b-col
                      >
                      <b-col></b-col>
                    </b-row>
                    <b-row>
                      <b-col><b-img :src="wifi2" width="100px" /></b-col>
                      <b-col align="center">
                        <b
                          ><br />
                          <b>
                            <h1>{{ wifi_users.hotspot }}</h1></b
                          >
                          <h5>จุดเชื่อมต่อ</h5>
                        </b></b-col
                      >
                      <b-col></b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col xl="6" md="12">
                  <b-card class="chat-widget" no-body>
                    <div>
                      <h4>การเชื่อมไวไฟ</h4>
                    </div>

                    <section class="chat-app-window">
                      <vue-perfect-scrollbar
                        :settings="perfectScrollbarSettings"
                        class="user-chats scroll-area"
                      >
                        <div class="chats">
                          <div>
                            <div
                              class="chat-body"
                              v-for="(item, index) in branch"
                              :key="index"
                            >
                              <div>
                                <b-media no-body>
                                  <b-media-aside class="mr-75">
                                    <b-avatar
                                      rounded
                                      size="80"
                                      :src="house_wifi"
                                    />
                                  </b-media-aside>
                                  <b-media-body class="my-auto">
                                    <h5 class="mb-0">
                                      <b>
                                        {{ item.name }}
                                        {{ item.total_connectWIFI }} ครั้ง</b
                                      >
                                    </h5>
                                    <!-- <small> employee.designation </small> -->
                                  </b-media-body>
                                </b-media>

                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </vue-perfect-scrollbar>
                    </section>
                    <br />
                    <br /><br /><br />
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- --------------- -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BBadge,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import flatPickr from "vue-flatpickr-component";
import mobile from "@/assets/images/non/mobile.svg";
import desktop from "@/assets/images/non/desktop.svg";
import tablet from "@/assets/images/non/tablet.svg";
import phone from "@/assets/images/non/phone.svg";
import img_email from "@/assets/images/non/email.svg";
import house_wifi from "@/assets/images/non/house_wifi.svg";
import people from "@/assets/images/non/people.svg";
import imgmale from "@/assets/images/non/imgmale.svg";
import imgfemale from "@/assets/images/non/imgfemale.svg";
import timeback from "@/assets/images/non/timeback.svg";
import Calendar from "@/assets/images/non/Calendar.svg";
import UserNew from "@/assets/images/non/UserNew.svg";
import User from "@/assets/images/non/user.svg";
import wifi2 from "@/assets/images/non/wifi2.svg";
import user_return from "@/assets/images/non/user_return.svg";
import clock from "@/assets/images/non/clock.svg";
import IOS from "@/assets/images/non/ios.svg";
import Android from "@/assets/images/non/android.svg";
import Other from "@/assets/images/non/other.svg";
import DEVICES_Other from "@/assets/images/non/DEVICES_Other.svg";
import Age from "./people_analytics/AgeChartUser.js";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    VueApexCharts,
    VuePerfectScrollbar,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    flatPickr,
    Age,
    apexchart: VueApexCharts,
  },
  setup() {
    return {
      mobile,
      desktop,
      tablet,
      phone,
      img_email,
      house_wifi,
      wifi2,
      User,
      UserNew,
      Calendar,
      timeback,
      imgfemale,
      imgmale,
      people,
      user_return,
      clock,
      IOS,
      Android,
      Other,
      DEVICES_Other,
    };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
        wheelPropagation: false,
      },
      datelabel: "7 วันที่ผ่านมา",
      customdate: false,
      fromDate: null,
      toDate: null,
      daterange: 1,
      rangeDate: null,
      name_user: JSON.parse(localStorage.getItem("userData")),
      branch: null,
      os: null,
      pieChartData: null,
      wifi_users: null,
      data_all: null,
      sessionsByDeviceDonut: {
        series: [],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.warning, $themeColors.primary],
        },
      },
      genderByDeviceDonut: {
        series: [],
        chartOptions: {
          labels: [],
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.warning, $themeColors.primary],
        },
      },
      Loading: false,
      analytics: null,
      gender: {
        series: [],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: "$ (thousands)",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " thousands";
              },
            },
          },
        },
      },
      users_return: 0,
      users_return_back: 0,
      day_current: {},
      day_retroact: {},
      sum_total_current: 0,
      sum_total_retroact: 0,
      day_data: [],
      sum_time__total_current: 0,
      sum_time__total_retroact: 0,
      time_data: [],
      data_os: [],
      data_device: [],
      not_data: false,
    };
  },
  beforeMount() {
    this.selectdate(this.daterange);
  },
  computed: {},
  mounted() {},
  methods: {
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      }
    },
    selectdate(daterange) {
      this.loaded = false;
      this.daterange = daterange;
      // console.log("daterange :>> ", daterange);
      var today = this.$moment();
      if (daterange == 1) {
        this.datelabel = "7 วันที่ผ่านมา";

        this.fromDate = today.subtract(6, "days").format("YYYY-MM-DD 00:00:00");
        this.toDate = this.$moment().format("YYYY-MM-DD 23:59:59");
        this.$nextTick(() => {
          this.loaddata();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.customdate = false;
        this.datelabel = "เดือนนี้";
        this.fromDate = this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        this.toDate = this.$moment()
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59");
        // console.log(this.fromDate);
        // console.log(this.toDate);
        this.$nextTick(() => {
          this.loaddata();
        });
      }

      // console.log(currentDateWithFormat);
    },
    async loaddata() {
      this.Loading = true;
      const params = {
        store_id: localStorage.getItem("store_id"),
        user_main: this.name_user.user_main,
        fromDate: this.fromDate,
        toDate: this.toDate,
        daterange: this.daterange,
      };
      // console.log('params :>> ', params);
      api
        .post("dashboard", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("dashboard response:>> ", response.data);
          this.data_all = response.data;
          this.branch = response.data.branch;
          this.os = response.data.os;
          this.wifi_users = response.data.wifi_users[0];
          this.users_return = response.data.users_return;
          if (response.data.wifi_users[0].user_all_total == 0) {
            // console.log("user all :>> ", 0);
            this.Loading = false;
          }
          this.users_return_back = response.data.users_return_back;
          this.day_current = response.data.day_current;
          this.day_retroact = response.data.day_retroact;
          //***********OS*********** */
          this.data_os = [];
          var sum_device_ios = (
            (this.wifi_users.d_IOS * 100) /
            (this.wifi_users.d_IOS +
              this.wifi_users.d_Android +
              this.wifi_users.d_other)
          ).toFixed(0);
          var sum_device_Android = (
            (this.wifi_users.d_Android * 100) /
            (this.wifi_users.d_IOS +
              this.wifi_users.d_Android +
              this.wifi_users.d_other)
          ).toFixed(0);
          var sum_device_other = (
            (this.wifi_users.d_other * 100) /
            (this.wifi_users.d_IOS +
              this.wifi_users.d_Android +
              this.wifi_users.d_other)
          ).toFixed(0);
          if (sum_device_ios > sum_device_Android) {
            this.data_os.push(
              sum_device_ios,
              "IOS",
              100 - sum_device_ios,
              "Other"
            );
          } else {
            this.data_os.push(
              sum_device_Android,
              "Android",
              100 - sum_device_Android,
              "Other"
            );
          }

          // console.log("data_os :>> ", this.data_os);

          //***********device*********** */
          this.data_device = [];
          var sum_tablet = (
            (this.wifi_users.d_tablet * 100) /
            (this.wifi_users.d_tablet +
              this.wifi_users.d_mobile +
              this.wifi_users.d_desktop)
          ).toFixed(0);
          var sum_mobile = (
            (this.wifi_users.d_mobile * 100) /
            (this.wifi_users.d_tablet +
              this.wifi_users.d_mobile +
              this.wifi_users.d_desktop)
          ).toFixed(0);
          var sum_desktop = (
            (this.wifi_users.d_desktop * 100) /
            (this.wifi_users.d_tablet +
              this.wifi_users.d_mobile +
              this.wifi_users.d_desktop)
          ).toFixed(0);

          if (sum_tablet > sum_mobile) {
            console.log("tablet");
            this.data_device.push(sum_tablet, "tablet");
            this.data_device.push(100 - parseInt(sum_tablet), "Other");
          } else if (sum_mobile > sum_tablet) {
            console.log("mobile");
            this.data_device.push(sum_mobile, "mobile");
            this.data_device.push(100 - parseInt(sum_mobile), "Other");
          } else if (sum_mobile === sum_tablet) {
            this.data_device.push(parseInt(sum_mobile), "mobile");
            this.data_device.push(100 - parseInt(sum_mobile), "Other");
          } else {
            console.log("desktop");
            this.data_device.push(sum_desktop, "desktop");

            this.data_device.push(100 - parseInt(sum_desktop), "Other");
          }
          // console.log("this.data_device :>> ", this.data_device);

          //**************** */
          var day = [];
          var total = [];
          this.day_data = [];
          for (var i = 0; i < this.day_current.length; i++) {
            day.push(this.day_current[i].day);
            total.push(this.day_current[i].total);
            this.sum_total_current += this.day_current[i].total;
          }
          this.day_data.push(day[total.indexOf(Math.max(...total))]);
          this.day_data.push(
            ((Math.max(...total) * 100) / this.sum_total_current).toFixed(0)
          );

          this.time_data = [];
          if (response.data.Time_Current.length > 0) {
            this.time_data.push(response.data.Time_Current[0].Hour);
            for (var i = 0; i < response.data.Time_Current.length; i++) {
              this.sum_time__total_current +=
                response.data.Time_Current[i].total;
            }
            this.time_data.push(
              (
                (response.data.Time_Current[0].total * 100) /
                this.sum_time__total_current
              ).toFixed(0)
            );
          }

          var sessionsByDeviceDonut_series = [];
          sessionsByDeviceDonut_series.push(this.wifi_users.sing_up_From);
          sessionsByDeviceDonut_series.push(this.wifi_users.sing_up_Line);

          this.sessionsByDeviceDonut = {
            ...this.sessionsByDeviceDonut,
            ...{
              series: sessionsByDeviceDonut_series,
              chartOptions: {
                labels: ["From Register", "Line"],
                dataLabels: {
                  enabled: true,
                },
                colors: response.data.brand_color,
                colorsText: response.data.brand_color,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };

          this.genderByDeviceDonut = {
            ...this.sessionsByDeviceDonut,
            ...{
              series: [
                parseInt(
                  (
                    (this.wifi_users.gender_female * 100) /
                    this.wifi_users.user_all
                  ).toFixed(0)
                ),
                parseInt(
                  (
                    (this.wifi_users.gender_male * 100) /
                    this.wifi_users.user_all
                  ).toFixed(0)
                ),
              ],
              chartOptions: {
                labels: ["หญิง", "ชาย"],
                dataLabels: {
                  enabled: true,
                },
                colors: response.data.brand_color,
                colorsText: response.data.brand_color,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                          fontFamily: "Montserrat",
                        },
                        value: {
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                          formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}`;
                          },
                        },
                        total: {
                          show: false,
                          fontSize: "1.5rem",
                          label: "Operational",
                          formatter() {
                            return "31%";
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };

          this.gender = {
            ...this.gender,
            ...{
              series: [
                {
                  name:
                    "หญิง " +
                    (
                      (this.wifi_users.gender_female * 100) /
                      this.wifi_users.user_all
                    ).toFixed(0) +
                    "%",
                      // data: [56,34,78,5,56,45],
                  data: [
                    this.wifi_users.female_age18_24,
                    this.wifi_users.female_age25_34,
                    this.wifi_users.female_age35_44,
                    this.wifi_users.female_age45_54,
                    this.wifi_users.female_age55_64,
                    this.wifi_users.female_age65_120
                  ],
                },
                {
                  name:
                    "ชาย " +
                    (
                      (this.wifi_users.gender_male * 100) /
                      this.wifi_users.user_all
                    ).toFixed(0) +
                    "%",
                  // data: [56,34,78,5,56,45],
                  data: [
                    this.wifi_users.male_age18_24,
                    this.wifi_users.male_age25_34,
                    this.wifi_users.male_age35_44,
                    this.wifi_users.male_age45_54,
                    this.wifi_users.male_age55_64,
                    this.wifi_users.male_age65_120
                  ],
                },
              ],
              chartOptions: {
                chart: {
                  type: "bar",
                  height: 350,
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "50%",
                    endingShape: "rounded",
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ["transparent"],
                },
                xaxis: {
                  categories: [
                    "18-24",
                    "25-34",
                    "35-44",
                    "45-54",
                    "55-64",
                    "65-ขึ่้นไป",
                  ],
                },
                yaxis: {
                  title: {
                    text: " คน",
                  },
                },
                fill: {
                  opacity: 1,
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return "จำนวน " + val + " คน";
                    },
                  },
                },
              },
            },
          };

          this.Loading = false;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fe718d;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
