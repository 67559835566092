// Sales Widget
import { Bar } from 'vue-chartjs'
import axios from 'axios';

export default ({
   extends: Bar,
   props: {

      wifi_users: {
         required: true,
      },

   },
   data: function () {
      return {
         color: {
            'primary': '#5D92F4',
            'warning': '#FFB70F',
            'danger': '#FF3739',
            'success': '#00D014',
            'info': '#00D0BD',
            'white': '#fff',
            'lightGrey': '#E8ECEE',
            'series1': '#ffe700',
            'series2': '#00d4bd',
            'series3': '#826bf8',
         },
         lineChartAxesColor: '#E9ECEF',
         legendFontColor: '#AAAEB3', // only works on react chart js 2
         chartGridColor: '#EAEAEA',
         axesColor: '#657786',
         shadowColor: 'rgba(0,0,0,0.3)',
         chartdata: null,
         options: {
            elements: {
               rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom',
               },
            },
            
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
               display: false,
            },
            scales: {
               yAxes: [{
                  stacked: true,
                  ticks: {
                     display: true
                  },
                  gridLines: {
                     display: false,
                     drawBorder: false
                  }
               }],
               xAxes: [{
                  stacked: true,
                  ticks: {
                     padding: 10
                  },
                  barPercentage: 0.4,
                  categoryPercentage: 1.3,
                  gridLines: {
                     display: true,
                     drawBorder: false
                  }
               }]
            },
             xaxis: {
                  type: "datetime",
                  categories: this.wifi_users.day,
                },
         }
      }
   },
   watch: {},
   mounted() {
      // console.log('this.wifi_users :>> ', this.wifi_users);
      // console.log('this.wifi_users.day :>> ', this.wifi_users.day);
      // console.log('this.wifi_users.data_users_new :>> ', this.wifi_users.data_users_new);
      // console.log('this.data_users_return :>> ', this.wifi_users.data_users_return);
      this.renderChart({
         labels:this.wifi_users.day,
         datasets: [
            {
               type: 'bar',
               label: 'New User',
               backgroundColor: '#82fdd6',
               hoverBackgroundColor: '#82fdd6',
               borderWidth: 0,
               data:this.wifi_users.data_users_new,
            },
            {
               type: 'bar',
               label: 'Return User',
               backgroundColor: '#dbd877',
               hoverBackgroundColor: '#dbd877',
               borderWidth: 0,
               data: this.wifi_users.data_users_return,
            }
         ]
      }, this.options)



   }
})
